import React, { Component } from 'react';
import { string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import isEqual from 'lodash/isEqual';
// import { propTypes } from '../../../util/types';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import * as validators from '../../util/validators';
import arrayMutators from 'final-form-arrays';


import {
  Form,
  Button,
  FieldTextInput,
  H3,
  H1,
  FieldCheckboxGroup,
  H4,
} from '../../components';

import css from './UserSendInvitationForm.module.css';

class UserSendInvitationFormComponent extends Component {
  constructor(props) {
    super(props);
    this.uploadDelayTimeoutId = null;
    this.state = {
      uploadDelay: false
    };
    this.submittedValues = {};
  }

  componentDidUpdate(prevProps) {
    // Upload delay is additional time window where Avatar is added to the DOM,
    // but not yet visible (time to load image URL from srcset)
    if (prevProps.uploadInProgress && !this.props.uploadInProgress) {
      this.setState({ uploadDelay: true });
      this.uploadDelayTimeoutId = window.setTimeout(() => {
        this.setState({ uploadDelay: false });
      }, UPLOAD_CHANGE_DELAY);
    }
  }

  componentWillUnmount() {
    window.clearTimeout(this.uploadDelayTimeoutId);
  }


  render() {
    return (
      <FinalForm
        keepDirtyOnReinitialize={true}
        mutators={{ ...arrayMutators }}
        {...this.props}
        render={fieldRenderProps => {
          const {
            className,
            handleSubmit,
            intl,
            invalid,
            pristine,
            rootClassName,
            form,
            values,
            updateInProgress,
            uploadInProgress,
          } = fieldRenderProps;

          // First name
          const firstNameLabel = intl.formatMessage({
            id: 'ContactUsForm.firstNameLabel',
          });
          const firstNamePlaceholder = intl.formatMessage({
            id: 'ContactUsForm.firstNamePlaceholder',
          });
          const firstNameRequiredMessage = intl.formatMessage({
            id: 'ProfileSettingsForm.firstNameRequired',
          });
          const firstNameRequired = validators.required(firstNameRequiredMessage);


          // Last name
          const lastNameLabel = intl.formatMessage({
            id: 'ContactUsForm.lastNameLabel',
          });
          const lastNamePlaceholder = intl.formatMessage({
            id: 'ContactUsForm.lastNamePlaceholder',
          });
          const lastNameRequiredMessage = intl.formatMessage({
            id: 'ProfileSettingsForm.lastNameRequired',
          });
          const lastNameRequired = validators.required(lastNameRequiredMessage);

          // email
          const emailLabel = intl.formatMessage({
            id: 'ContactUsForm.emailLabel',
          });
          const emailPlaceholder = intl.formatMessage({
            id: 'ContactUsForm.emailPlaceholder',
          });

          // message
          const messageLabel = intl.formatMessage({
            id: 'ContactUsForm.messageLabel',
          });
          const messagePlaceholder = intl.formatMessage({
            id: 'ContactUsForm.messagePlaceholder',
          });

          //description
          const descriptionLabel = intl.formatMessage({
            id: 'ContactUsForm.descriptionLabel',
          });
          const descriptionPlaceholder = intl.formatMessage({
            id: 'ContactUsForm.descriptionPlaceholder',
          });


          const classes = classNames(rootClassName || css.root, className);
          const submitInProgress = updateInProgress;
          const submittedOnce = Object.keys(this.submittedValues).length > 0;
          const pristineSinceLastSubmit = submittedOnce && isEqual(values, this.submittedValues);
          const submitDisabled =
            invalid || pristine || pristineSinceLastSubmit || uploadInProgress || submitInProgress;


          return (
            <Form
              className={classes}
              onSubmit={e => {
                this.submittedValues = values;
                handleSubmit(e);
                form.reset();
              }}
            >
              <div className={css.sectionContainer}>

                <H4 as="h2" className={css.sectionTitle}>
                  <FormattedMessage id="ContactUsForm.invitationClient" />
                </H4>

                <div className={css.contactInnerWrapper}>
                  <div className={css.nameContainer}>
                    <div className={css.nameInput}>
                      <FieldTextInput
                        className={css.inputBox}
                        type="text"
                        id="firstName"
                        name="firstName"
                        label={firstNameLabel}
                        placeholder={firstNamePlaceholder}
                        validate={firstNameRequired}
                      />
                    </div>
                    <FieldTextInput
                      className={css.inputBox}
                      type="text"
                      id="lastName"
                      name="lastName"
                      label={lastNameLabel}
                      placeholder={lastNamePlaceholder}
                      validate={lastNameRequired}
                    />
                    <FieldTextInput
                      className={css.inputBox}
                      type="email"
                      id="email"
                      name="email"
                      label={emailLabel}
                      placeholder={emailPlaceholder}
                      validate={lastNameRequired}
                    />
                    <FieldTextInput
                      className={css.inputBox}
                      type="textarea"
                      id="message"
                      name="message"
                      label={messageLabel}
                      placeholder={messagePlaceholder}
                      validate={lastNameRequired}
                    />
                    <FieldTextInput
                      className={css.inputBox}
                      type="textarea"
                      id="description"
                      name="description"
                      label={descriptionLabel}
                      placeholder={descriptionPlaceholder}
                      validate={lastNameRequired}
                    />
                    <div className={css.formsubmitWrapper}>
                      <Button
                        className={css.submitButton}
                        type="submit"
                        disabled={submitDisabled}
                        inProgress={submitInProgress}
                        ready={pristineSinceLastSubmit}
                      >
                        <FormattedMessage id="ContactUsForm.sendInvitation" />
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          );
        }}
      />
    );
  }
}

UserSendInvitationFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  uploadImageError: null,
  updateProfileError: null,
  updateProfileReady: false,
};

UserSendInvitationFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  // from injectIntl
  intl: intlShape.isRequired,
};

const UserSendInvitationForm = compose(injectIntl)(UserSendInvitationFormComponent);

UserSendInvitationForm.displayName = 'UserSendInvitationForm';

export default UserSendInvitationForm;
