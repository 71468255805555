import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { fetchCurrentUser } from '../../ducks/user.duck';
import { types as sdkTypes, createImageVariantConfig } from '../../util/sdkLoader';
import { denormalisedResponseEntities } from '../../util/data';
import { storableError } from '../../util/errors';
import { createOrUpdateUser } from '../../util/api';
const { UUID } = sdkTypes;

// Return an array of image ids
const imageIds = images => {
  // For newly uploaded image the UUID can be found from "img.imageId"
  // and for existing listing images the id is "img.id"
  return images ? images.map(img => img.imageId || img.id) : null;
};

const getImageVariantInfo = listingImageConfig => {
  const { aspectWidth = 1, aspectHeight = 1, variantPrefix = 'listing-card' } = listingImageConfig;
  const aspectRatio = aspectHeight / aspectWidth;
  const fieldsImage = [`variants.${variantPrefix}`, `variants.${variantPrefix}-2x`];

  return {
    fieldsImage,
    imageVariants: {
      ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
      ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
    },
  };
};


// ================ Action types ================ //

export const SET_INITIAL_STATE = 'app/UserSendInvitationPage/SET_INITIAL_STATE';

export const SHOW_USER_REQUEST = 'app/UserSendInvitationPage/SHOW_USER_REQUEST';
export const SHOW_USER_SUCCESS = 'app/UserSendInvitationPage/SHOW_USER_SUCCESS';
export const SHOW_USER_ERROR = 'app/UserSendInvitationPage/SHOW_USER_ERROR';

export const CREATE_LISTING_DRAFT_REQUEST = 'app/UserSendInvitationPage/CREATE_LISTING_DRAFT_REQUEST';
export const CREATE_LISTING_DRAFT_SUCCESS = 'app/UserSendInvitationPage/CREATE_LISTING_DRAFT_SUCCESS';
export const CREATE_LISTING_DRAFT_ERROR = 'app/UserSendInvitationPage/CREATE_LISTING_DRAFT_ERROR';

// ================ Reducer ================ //

const initialState = {
  userId: null,
  userShowError: null,
  submittedListingId: null,
  createListingDraftInProgress: false,
  createListingDraftError: null,
};

export default function UserSendInvitationPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_STATE:
      return { ...initialState };
    case SHOW_USER_REQUEST:
      return { ...state, userShowError: null };
    case SHOW_USER_SUCCESS:
      return { ...state, userId: payload };
    case SHOW_USER_ERROR:
      return { ...state, userShowError: payload };

    case CREATE_LISTING_DRAFT_REQUEST:
      return {
        ...state,
        createListingDraftInProgress: true,
        createListingDraftError: null,
        submittedListingId: null,
        listingDraft: null,
      };

    case CREATE_LISTING_DRAFT_SUCCESS:
      return {
        ...state,
        ...updateUploadedImagesState(state, payload),
        createListingDraftInProgress: false,
        submittedListingId: payload.data.id,
        listingDraft: payload.data,
      };
    case CREATE_LISTING_DRAFT_ERROR:
      return {
        ...state,
        createListingDraftInProgress: false,
        createListingDraftError: payload,
      };


    default:
      return state;
  }
}

// ================ Action creators ================ //

export const setInitialState = () => ({
  type: SET_INITIAL_STATE,
});

export const showUserRequest = userId => ({
  type: SHOW_USER_REQUEST,
});

export const showUserSuccess = (userData) => ({
  type: SHOW_USER_SUCCESS,
  payload: userData
});

export const showUserError = e => ({
  type: SHOW_USER_ERROR,
  error: true,
  payload: e,
});

export const createListingDraftRequest = params => ({
  type: CREATE_LISTING_DRAFT_REQUEST
});
export const createListingDraftSuccess = (response) => ({
  type: CREATE_LISTING_DRAFT_SUCCESS,
  payload: response
});
export const createListingDraftError = e => ({
  type: CREATE_LISTING_DRAFT_ERROR,
  error: true,
  payload: e
})

// ================ Thunks ================ // 

export const showUser = userId => (dispatch, getState, sdk) => {
  dispatch(showUserRequest(userId));
  return sdk.users
    .show({
      id: userId,
      include: ['profileImage'],
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
    })
    .then(response => {
      const userData = response;
      dispatch(showUserSuccess(userData));
      return response;
    })
    .catch(e => dispatch(showUserError(storableError(e))));
};

export const requestCreateListingDraft = (params) => async (dispatch, getState, sdk) => {
  const { firstName, lastName, email, message, description, dateAndTime, photoGrapherId } = params;

  return createOrUpdateUser({ firstName, lastName, email, photoGrapherId })
    .then((user) => {
      const {data, userAlreadyExist} = user || {};

      const listingData = {
        title: `${firstName} ${lastName}`,
        description: description,
        publicData: {
          email: email,
          dateAndTime: dateAndTime,
          customer: data?.data?.id?.uuid,
          transactionProcessAlias:"default-purchase/release-1",
          unitType:"item"
        },
      }

      return sdk.ownListings
        .createDraft(listingData)
        .then(response => {
          return {listingData: response, userAlreadyExist: userAlreadyExist};
        })
        .catch(e => {
          return storableError(e);
        });
    })
};

export const loadData = (params, search, config) => (dispatch, getState, sdk) => {

  const currentUser = getState().user.currentUser;
  const userId = currentUser?.id?.uuid;
  // Clear state so that previously loaded data is not visible
  // in case this page load fails.
  dispatch(setInitialState());

  return Promise.all([
    dispatch(fetchCurrentUser()),
    dispatch(showUser(userId)),
  ]);
};
